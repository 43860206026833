import React, { useState, useEffect } from "react";
import Navbar from "../../components/general/Navbar";
import { grandOpeningImgs } from "../../data";
import Gallery from "../../components/AboutUs/Gallery";
import Donation from "../../components/general/Donation";
import Footer from "../../components/general/Footer";
import { Link } from "react-router-dom";
import { FadeInWhenVisible } from "../../components/animate";
function Media() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const start =
    currentPage * itemsPerPage >= grandOpeningImgs.length
      ? 1 * itemsPerPage
      : (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = grandOpeningImgs.slice(start, end);
  useEffect(() => {
    function slider() {
      currentPage * itemsPerPage >= grandOpeningImgs.length
        ? setCurrentPage(1)
        : setCurrentPage(currentPage + 1);
    }
    setTimeout(slider, 3000);
  }, [currentPage]);

  return (
    <section style={{ background: "#F3F3F3" }} className="overflow-hidden">
      <Navbar dark />
      <div className="flex flex-col sm:flex-row items-center justify-between pt-20">
        <div className="w-full sm:w-3/4 lg:w-2/5 sm:ml-14 py-20 px-6 sm:px-10 sm:px-0">
          <p className="font-semibold text-xs pb-5 leading-6 lg:pr-10">
            See pictures and videos from the commissioning and dedication of our
            new clinic building. Relive the moments with us
          </p>
          <button className="px-8 py-4 bg-btn">
            <Link to="/grand_opening">See Pictures</Link>
          </button>
        </div>
        <div className="flex items-center justify-center">
          {currentData.map((media) => (
            <div className="mediaTop">
              <FadeInWhenVisible>
                <img src={media} alt="" />
              </FadeInWhenVisible>
            </div>
          ))}
        </div>
      </div>
      <Gallery />
      <Donation />
      <Footer />
    </section>
  );
}

export default Media;
