import { useState, useEffect } from "react";
import CardPagination from "../general/CradPagination";
import { patientsCard } from "../../data";

function Patients() {
  const width = window.screen.width;
  const [item, setItem] = useState(3);
  useEffect(() => {
    width >= 1024 && setItem(3);
    width < 1024 && setItem(2);
    width < 600 && setItem(1);
  }, [width]);
  return (
    <section style={{ background: "#F3F3F3" }} className="py-20 relative">
      <h1 className="headerTextColor font-bold text-2xl text-center sm:text-left sm:ml-28 pb-10">
        MEET OUR PATIENTS
      </h1>
      <div className="flex justify-center items-center mb-20">
        <CardPagination data={patientsCard} itemsPerPage={item} />
      </div>
    </section>
  );
}

export default Patients;
