import React from "react";
import pics1 from "../../images/about/gallery/gallery1.png";
import pics2 from "../../images/about/gallery/gallery2.png";
import pics3 from "../../images/about/gallery/gallery3.png";
import pics4 from "../../images/about/gallery/gallery4.png";
import pics5 from "../../images/about/gallery/gallery5.png";
import pics6 from "../../images/about/gallery/gallery6.png";
import pics7 from "../../images/about/gallery/gallery7.png";
import pics8 from "../../images/about/gallery/gallery8.png";
import pics9 from "../../images/about/gallery/gallery9.png";
import pics10 from "../../images/about/gallery/gallery10.png";
import pics11 from "../../images/about/gallery/gallery11.png";
import pics12 from "../../images/about/gallery/gallery12.png";
import pics13 from "../../images/about/gallery/gallery13.png";
import pics14 from "../../images/about/gallery/gallery14.png";
import pics15 from "../../images/about/gallery/gallery15.png";
import pics16 from "../../images/about/gallery/gallery16.png";
import pics17 from "../../images/about/gallery/gallery17.png";
import pics18 from "../../images/about/gallery/gallery18.png";
import pics19 from "../../images/about/gallery/gallery19.png";
import pics20 from "../../images/about/gallery/gallery20.png";
import pics21 from "../../images/about/gallery/gallery21.png";
import pics22 from "../../images/about/gallery/gallery22.png";
import pics23 from "../../images/about/gallery/gallery23.png";
import pics24 from "../../images/about/gallery/gallery24.png";
import pics25 from "../../images/about/gallery/gallery25.png";
import pics26 from "../../images/about/gallery/gallery26.png";
import pics27 from "../../images/about/gallery/gallery27.png";
import { FadeInWhenVisible } from "../animate";
const gallery = [
  pics1,
  pics2,
  pics3,
  pics4,
  pics5,
  pics6,
  pics7,
  pics8,
  pics9,
  pics10,
  pics11,
  pics12,
  pics13,
  pics14,
  pics15,
  pics16,
  pics17,
  pics18,
  pics19,
  pics20,
  pics21,
  pics22,
  pics23,
  pics24,
  pics25,
  pics26,
  pics27,
];
function Gallery() {
  return (
    <section className="pb-20">
      <h1 className="font-bold text-3xl text-center py-10">Gallery</h1>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 items-center px-2 sm:px-10 place-items-center w-full">
        {gallery.map((pic, i) => (
          <div key={i} className="">
            <FadeInWhenVisible>
              <img src={pic} alt="" className="" />
            </FadeInWhenVisible>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Gallery;
