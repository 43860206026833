import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Team from "./pages/AboutUs/Team";
import Media from "./pages/AboutUs/Media";
import Service from "./pages/Services/Service";
import Outreach from "./pages/Services/Outreach";
import ContactUs from "./pages/ContactUs";
import GrandOpening from "./pages/AboutUs/GrandOpening";
import Lab from "./pages/Services/Lab";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/media" element={<Media />} />
          <Route path="/grand_opening" element={<GrandOpening />} />
          <Route path="/service" element={<Service />} />
          <Route path="/outreach" element={<Outreach />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/lab" element={<Lab />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
