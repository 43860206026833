import React from "react";
import { FadeInWhenVisible } from "../animate";

function Banner({ heading, text, bg }) {
  return (
    <section
      className={`${bg} h-64 bg-red-500 flex items-center justify-center`}
    >
      <FadeInWhenVisible>
        <h1 className="ml-5 sm:ml-0 text-3xl font-bold text-white">
          {heading}
        </h1>
        <h1 className="text-base sm:text-xl font-bold text-white ml-16 italic">
          {text}
        </h1>
      </FadeInWhenVisible>
    </section>
  );
}

export default Banner;
