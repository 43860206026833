import React from "react";
import Navbar from "../../components//general/Navbar";
import Banner from "../../components/AboutUs/Banner";
import pics from "../../images/about/aboutPics.png";
import Donation from "../../components/general/Donation";
import Footer from "../../components/general/Footer";
import { aboutCard } from "../../data";
import { SlideLeft, SlideRight, SlideUp } from "../../components/animate";
import PlayVideoCard from "../../components/general/PlayVideoCard";
function AboutUs() {
  return (
    <section>
      <Navbar />
      <Banner heading="Who We Are" text="A Reason To Hope" bg="bg-about" />
      <div
        style={{ background: "#f3f3f3" }}
        className="m-auto py-5 sm:py-20 relative z-0"
      >
        <div className="flex flex-col lg:flex-row items-center justify-center px-5 sm:px-20">
          <div className="lg:w-2/4 lg:-mr-20 z-10">
            <SlideLeft>
              <p className="lg:bg-white font-semibold rounded-xl lg:shadow-lg overflow-hidden py-4 sm:px-8 text-sm leading-6">
                Oyiji Odey Sickle Cell Program (OOSCP) opened its doors to the
                community on September 12, 2000. The Cross River State Ministry
                of Health gave us permission to work at the General Hospital
                Ogoja. I am excited to see my dream come to life with the help
                of some wonderful people who believed in making a difference in
                people’s lives. Inaugurated on September 12, 2000, OOSCP has
                registered over 360 patients with sickle cells. These patients
                receive hematinic and anti-malaria medicine free of charge.We
                have a registered nurse and a doctor is our primary care
                physician as well as the secretary of OOSCP. Our office is open
                to the public on Mondays and Wednesdays. Our days and hours of
                operation will increase with the availability of funds.
              </p>
            </SlideLeft>
          </div>
          <div className="lg:w-2/4">
            <SlideRight>
              <img src={pics} alt="" />
            </SlideRight>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-2 justify-start items-center pt-10 sm:pt-20 px-5 lg:px-0">
          {aboutCard.map((item, i) => (
            <div key={i}>
              <SlideUp>
                <div
                  style={{ background: "#6DD65D" }}
                  className="h-72 w-full p-5 mr-3 sm:mr-0 "
                >
                  <h2 className="font-bold text-2xl text-center my-3">
                    {item.heading}
                  </h2>
                  <p className="font-medium text-sm leading-8 sm:leading-6">
                    {item.text}
                  </p>
                </div>
              </SlideUp>
            </div>
          ))}
        </div>

        <PlayVideoCard type="home" />
      </div>
      <Donation />
      <Footer />
    </section>
  );
}

export default AboutUs;
