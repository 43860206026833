import React from "react";
import Navbar from "../components/general/Navbar";
import IntroSection from "../components/Home/IntroSection";
import Card from "../components/Card";
import profile from "../images/profilePics.png";
import Donation from "../components/general/Donation";
import Patients from "../components/Home/Patients";
import Footer from "../components/general/Footer";
import Katie from "../images/kartie.png";
import hope from "../images/hope.png";
import { SlideLeft, SlideRight } from "../components/animate";

export default function Home() {
  return (
    <div className="overflow-hidden">
      <Navbar />
      <IntroSection />
      <section className="pt-10 sm:pt-32">
        <div className="flex flex-wrap justify-center items-center mb-10 sm:mb-20">
          <Card />
        </div>
        <div className="flex flex-col-reverse sm:flex-row justify-center items-center pb-5 sm:pb-16">
          <div className="w-3/4 mt-10 md:mt-0 md:w-1/5">
            <SlideLeft>
              <div className="bg-black p-2 rounded-xl">
                <img src={profile} alt="" className="w-full" />
              </div>
            </SlideLeft>
          </div>
          <div className="sm:w-2/6 mx-10 lg:mx-0 lg:pl-10 text-left">
            <SlideRight>
              <h1 className="font-bold text-2xl headerTextColor">
                ABOUT OYIJI ODEY
              </h1>
              <p className="text-sm font-medium">
                Even though Oyiji lived for only 13 years, she made a big
                impact. Her battle with the sickle cell disease made her
                determined to become a doctor, and her goal was to help people
                like her.
              </p>
              <p className="text-sm font-medium pt-5">
                OOSCP was designed with Oyiji's dream in mind. It has helped
                hundreds of people with sickle cell disease. Now a research
                center and clinic has been established in her honor.
              </p>
            </SlideRight>
          </div>
        </div>
        <section>
          <div className="flex flex-col-reverse md:flex-row justify-center items-center md:items-start py-20 mt-10">
            <div className="mt-10 md:mt-0 w-pics">
              <SlideLeft>
                <img src={Katie} alt="" className="w-full" />
              </SlideLeft>
            </div>
            <div className="sm:w-3/6 mx-10 lg:mx-0 lg:pl-10 text-left">
              <SlideRight>
                <h1 className="font-bold text-xl headerTextColor">
                  A Greater Purpose
                </h1>
                <p className="text-sm text-black leading-6">
                  Oyiji Odey Sickle Program serves a purpose far greater than
                  mine. A sickle cell clinic and research center turned out to
                  be God’s plan all along. It started with a vow to God
                  following the death of my little sister, a reason to hope, and
                  now a realization of a dream.  I started OOSCP in 2000, and my
                  aunt, Mrs. Ekpo, helped me with all the paperwork involved and
                  registered it as NGO at home. My late dad, Barrister
                  MacJohnson Odey, provided the legal counsel we needed. The
                  late, Daddy Agabi played a pivotal role in keeping this
                  clinic. God reminds us in Isaiah 55 8-9; "My plans are not
                  your plans, nor are your ways my ways, says the Lord. Just as
                  the heavens are higher than the earth, so are my ways higher
                  than your ways, and my plans than your plans."
                </p>
              </SlideRight>
              <SlideRight>
                <div className="mt-10">
                  <h4 className="text-sm font-medium ">Katie Ogeyi Odey</h4>
                  <h5 className="text-xs font-bold ">Founder/CEO</h5>
                </div>
              </SlideRight>
            </div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row justify-center items-center pb-16">
            <div className="w-4/5 mt-10 md:mt-0 sm:w-2/5">
              <SlideLeft>
                <img src={hope} alt="" className="w-full" />
              </SlideLeft>
            </div>
            <div className="sm:w-2/6 lg:w-1/5 mx-10 lg:mx-0 lg:pl-5 text-left">
              <SlideRight>
                <h1 className="font-bold text-xl text-black">
                  Here for a Purpose
                </h1>
                <p className="text-sm font-medium leading-7">
                  <span>A wish upon the north star </span>
                  <br />
                  <span> A dream that touched God’s heart</span>
                  <br />
                  <span> Open doors of faith now bring us joy </span>
                  <br />
                  <span> Spreading hope on silver wings </span>
                  <br />
                  <span>For it was God’s plan all along </span>
                  <br />
                  <span>We could not have imagined </span>
                  <br />
                  <span>The enduring love of a sister </span>
                  <br />
                  <span>Found a purpose in an awful loss </span>
                  <br />
                  <span>With a simple vow to spread hope </span>
                  <br />
                  <span>For my purpose in life is far </span>
                  <br />
                  <span>Greater than myself</span>
                </p>
              </SlideRight>
            </div>
          </div>
        </section>
      </section>
      <Patients />
      <Donation />
      <Footer />
    </div>
  );
}
