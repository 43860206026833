import React from "react";
import { SlideLeft, SlideRight } from "../animate";
import { Link } from "react-router-dom";
export default function Donation() {
  return (
    <section className="flex justify-center items-center bg-donation">
      <div className="text-slate-100 text-center">
        <SlideLeft>
          <h5 className="font-bold text-base sm:text-xl">
            Help a Child Living with Sickle Cell
          </h5>
          <h1 className="font-bold text-xl sm:text-4xl">
            Make A Donation Today
          </h1>
        </SlideLeft>
        <SlideRight>
          <div className=" mt-5">
            <Link
              to="http://gogetfunding.com/oyiji-odey-sickle-cell-clinic-and-research-centre"
              target="_blank"
              className="py-4 px-8 bg-slate-100 headerTextColor font-bold"
            >
              Donate
            </Link>
          </div>
        </SlideRight>
      </div>
    </section>
  );
}
