import React from "react";
import Navbar from "../../components/general/Navbar";
import Banner from "../../components/AboutUs/Banner";
import Donation from "../../components/general/Donation";
import Footer from "../../components/general/Footer";
import { serviceCard } from "../../data";
import { SlideLeft, SlideUp } from "../../components/animate";

function Service() {
  return (
    <section>
      <Navbar />
      <Banner
        bg="bg-service"
        heading="What We Do"
        text="What services do we offer"
      />
      <div style={{ background: "#F3F3F3" }}>
        <p className="sm:text-center px-10 sm:px-20 lg:px-0 lg:w-2/4 m-auto py-5 sm:py-16 font-medium text-sm leading-7">
          <SlideLeft>
            Most treatments and services under OOSCP are free. Currently the
            number of regular patients is approximately 400. The more publicity
            this program gets over a period of time, the greater the number of
            patients in our program. We have moved to our permanent facility in
            ijibor, Bekwara. This has opened up opportunities to take in more
            patients and offer a wider range of services to those in our care.
          </SlideLeft>
        </p>
        <div className="grid sm:grid-cols-2 gap-2 lg:grid-cols-4 place-items-center pb-10 sm:pb-16 sm:-mt-10 sm:mt-0 px-5 lg:px-0">
          {serviceCard.map((item, i) => (
            <SlideUp>
              <div
                key={i}
                style={{ background: "#6DD65D" }}
                className="h-80 w-full p-5 flex items-center flex-col sm:mx-0 pt-10 lg:my-0"
              >
                <img src={item.icon} alt={item.heading} className="w-10 mb-3" />
                <h2 className="font-bold text-2xl text-center">
                  {item.heading}
                </h2>
                <p className="font-medium text-sm leading-6">{item.text}</p>
              </div>
            </SlideUp>
          ))}
        </div>
      </div>
      <Donation />
      <Footer />
    </section>
  );
}

export default Service;
