import React from "react";
import Navbar from "../../components/general/Navbar";
import Banner from "../../components/AboutUs/Banner";
import Footer from "../../components/general/Footer";
import { adminTeams, staffTeams } from "../../data";
import { FadeInWhenVisible } from "../../components/animate";
function Team() {
  return (
    <section className="overflow-hidden">
      <Navbar />
      <Banner heading="Meet Our Team" text="The work-force" bg="bg-team" />
      <h4 className="font-bold mt-14 -mb-0 sm:-mb-10 px-5 sm:px-28 text-2xl text-center sm:text-left">
        Administrators/Board
      </h4>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-start p-5 sm:p-20 text-center place-items-center relative z-0">
        {adminTeams.map((team, i) => (
          <div
            key={i}
            className="flex justify-center flex-col items-center w-64 py-3"
          >
            <FadeInWhenVisible>
              <img src={team.pic} alt="" className="h-80 rounded-3xl" />
              <h3 className="font-bold text-xl">{team.name}</h3>
              <p className="font-semibold text-sm">{team.title}</p>
            </FadeInWhenVisible>
          </div>
        ))}
      </div>
      <h4 className="font-bold mt-5 -mb-0 sm:-mb-10 px-5 sm:px-28 text-2xl text-center sm:text-left">
        Clinic Staff
      </h4>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-start p-5 sm:p-20 text-center place-items-center relative z-0">
        {staffTeams.map((team, i) => (
          <div
            key={i}
            className="flex justify-center flex-col items-center w-64 py-3"
          >
            <FadeInWhenVisible>
              <img src={team.pic} alt="" className="h-80 rounded-3xl" />
              <h3 className="italic font-bold text-xl">{team.name}</h3>
              <p className="font-semibold text-sm">{team.title}</p>
            </FadeInWhenVisible>
          </div>
        ))}
      </div>
      <Footer />
    </section>
  );
}

export default Team;
