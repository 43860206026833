import React from "react";
import Navbar from "../../components/general/Navbar";
import Banner from "../../components/AboutUs/Banner";
import Donation from "../../components/general/Donation";
import Footer from "../../components/general/Footer";
import pics from "../../images/service/labPics1.jpg";
import pics1 from "../../images/service/labPics2.jpg";
import pics2 from "../../images/service/labPics3.jpg";
import { SlideLeft, SlideRight, SlideUp } from "../../components/animate";

function Lab() {
  return (
    <section style={{ background: "#F3F3F3" }}>
      <Navbar />
      <Banner bg="bg-lab" heading="Joyce Lin Lab" />
      <div className="px-5 lg:px-24 py-10 md:py-20">
        <SlideLeft>
          <div className="flex flex-col lg:flex-row justify-between items-center p-5 sm:p-7 bg-white rounded-2xl shadow-lg">
            <img src={pics} alt="" className="lg:w-1/2 mb-5 lg:mb-0" />
            <p className="lg:w-1/2 font-medium text-sm lg:ml-10 leading-6 md:px-7 lg:px-0">
              The world knew her as a pilot with Mission Aviation Fellowship
              (MAF), but she was a sister and a dear friend to many. Her love
              for God and people was infectious. She was transporting rapid test
              kits and school supplies to a village in Papua, Indonesia when her
              plane crashed.We experience her love even after she passed and
              were blessed by her generosity. The lab at our research center is
              named after Joyce Lin, and we are delighted to know that her
              legacy continues. She is quoted as saying, “There is a famous
              verse that Christians like to quote from Romans 8:28, which says
              God is able to work all things together for the good of those he
              called according to his purpose. As I’ve looked back on my life,
              it has been cool to see the many ways in which this verse has been
              true in this calling to serve in Indonesia.” Today, God continues
              to use her legacy to change lives at Oyiji Odey Sickle Cell Clinic
              and Research Centre.
            </p>
          </div>
        </SlideLeft>
        <SlideRight>
          <div className="flex flex-col md:flex-row-reverse justify-between items-center p-5 sm:p-7 bg-white rounded-2xl my-5 sm:my-10 shadow-lg">
            <img src={pics1} alt="" className="md:w-1/2 mb-5 lg:mb-0" />
            <div className="md:mr-10 md:w-1/2 ">
              <h3 className="font-bold text-2xl mb-5">Laboratory Services</h3>
              <p className="font-medium text-sm leading-6">
                Besides sickle cell screening, the lab provides other screening
                to help to mitigate any symptoms of diseases that could be
                preventable or treatable. Our lab technician, Mr. Emmanuel
                Onyeledo, has over 22 years of experience screening and
                providing life-saving information for patients of all ages.
              </p>
            </div>
          </div>
        </SlideRight>
        <SlideUp>
          <div className="flex flex-col md:flex-row justify-between items-center p-5 sm:p-7 bg-white rounded-2xl shadow-lg">
            <img src={pics2} alt="" className="md:w-1/2 mb-5 lg:mb-0" />
            <div className="md:ml-10 md:w-1/2 ">
              <h3 className="font-bold text-2xl mb-5">
                Research at Joyce Lin Lab
              </h3>
              <p className="font-medium text-sm leading-6">
                Oyiji Odey Sickle Cell Clinic and Research Centre will welcome
                cohorts of students to participate in hands-on research on
                various aspects of sickle cell disease. Our work includes
                collaboration with various universities in Nigeria and research
                institutions in the United States. The goal is to provide
                training and resources to help new doctors gain experience in
                the management and treatment of sickle cell disease.
              </p>
            </div>
          </div>
        </SlideUp>
      </div>
      <Donation />
      <Footer />
    </section>
  );
}

export default Lab;
