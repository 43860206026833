import React from "react";
import { homeCard } from "../data";
import { SlideUp } from "./animate";
function Card() {
  return (
    <>
      {homeCard.map((data, i) => (
        <SlideUp
          className={
            "bg-homeCard rounded-xl headerTextColor shadow-sm overflow-hidden w-3/12 mx-8"
          }
        >
          <h2 className="text-2xl font-bold mb-2 py-7 text-center">
            {data.title}
          </h2>
          <img
            className="w-full h-64 object-cover object-center rounded-2xl"
            src={data.image}
            alt={data.title}
          />
          <p className="text-gray-700 text-xs leading-5 font-semibold p-5 text-left text-gray-900">
            {data.text}
          </p>
        </SlideUp>
      ))}
    </>
  );
}

export default Card;
