import React from "react";
import message from "../../images/message.png";
import loacation from "../../images/location.png";
import phone from "../../images/call.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="py-5 px-2 sm:px-10 bg-gray-900">
      <div className="flex flex-col sm:flex-row justify-between items center sm:border-b-2 py-5">
        <div className="block sm:hidden pb-5 text-lg font-bold text-gray-300 text-center md:text-left">
          <h3>OYIJI ODEY SICKLE CELL</h3>
          <h3>CLINIC AND RESEARCH CENTRE</h3>
        </div>
        <div className="flex flex-col justify-center items-center pb-4 sm:pb-0">
          <img
            src={phone}
            alt="phone_icon"
            className="hidden sm:block w-12 pb-4"
          />
          <Link to="tel:+2347069457031" className="text-base text-gray-300">
            +2347069457031
          </Link>
          <br className="hidden sm:block " />
        </div>
        <div className="flex flex-col justify-center items-center pb-4 sm:pb-0">
          <img
            src={message}
            alt="message_icon"
            className="hidden sm:block w-12 pb-4"
          />
          <Link
            to="mailto:oyijiodeysicklecellclinicnrc@gmail.com"
            className="text-base text-gray-300"
          >
            oyijiodeysicklecellclinicnrc@gmail.com
          </Link>
          <br className="hidden sm:block " />
        </div>
        <div className="flex flex-col justify-center items-center pb-4 sm:pb-0">
          <img
            src={loacation}
            alt="location_icon"
            className="hidden sm:block w-12 pb-4"
          />
          <p className="text-base text-gray-300 text-center">
            <span className="block">Behind Ijibor Health Centre, Ijibor,</span>
            <span className="block"> Bekwara, Cross River State, Nigeria</span>
          </p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-between sm:items-center sm:pt-10">
        <div className="hidden sm:block text-xl font-bold text-gray-300 text-center md:text-left">
          <h3>OYIJI ODEY SICKLE CELL</h3>
          <h3>CLINIC AND RESEARCH CENTRE</h3>
        </div>
        <ul className="font-normal sm:text-lg grid grid-cols-2 sm:grid-cols-3 place-items-center gap-5 sm:gap-4 sm:place-items-start text-gray-300">
          <li className="pb-3 text-base">
            <Link to="/">Home</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/team">Team</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/media">Gallery</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/about">About</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/lab">Research</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/service">Services</Link>
          </li>
          <li className="pb-3 text-base">
            <Link to="/outreach">DBC Outreach</Link>
          </li>
          <li className="pb-3 text-base">
            <Link>Resources</Link>
          </li>
          <li className="hidden sm:block pb-3">
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <div className="text-center my-5">
          <Link
            to="http://gogetfunding.com/oyiji-odey-sickle-cell-clinic-and-research-centre"
            target="_blank"
            className="bg-btn px-8 py-4"
          >
            Donate
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
