import React from "react";
import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import pics3 from "../../images/service/outreach3.png";
import pics4 from "../../images/service/outreach4.png";
import pics5 from "../../images/service/outreach5.png";
import {
  FadeInWhenVisible,
  SlideLeft,
  SlideRight,
  SlideUp,
} from "../../components/animate";
function Outreach() {
  return (
    <section>
      <Navbar />
      <section className="bg-outreach flex flex-col justify-center items-center text-center bg-gray-500 py-10">
        <FadeInWhenVisible>
          <h2 className="text-white text-3xl mt-5 sm:mt-20">SUPPORT FROM</h2>
          <h2 className="text-white text-3xl">DERWOOD BIBLE CHURCH</h2>
          <h5
            style={{ color: "#6DD65D" }}
            className="text-green-300 font-bold text-xl italic px-10"
          >
            Our faith connects us to a greater path
          </h5>
        </FadeInWhenVisible>

        <p className="text-left text-base py-5 px-5 tracking-wide md:px-0 md:w-2/4 text-white leading-7 tracking-widest">
          <SlideLeft>
            The task of caring for over 300 patients with sickle cell disease is
            enormous, but it has been made possible mostly by Derwood Bible
            Church in Maryland, USA and personal funds from family and friends.
            Since the clinic opened in September of 2000, patients from Ogoja
            and neighboring towns have benefited from the services of our
            devoted nurse, Mary Adie, our late coordinator, Mr. Jerome Agabi and
            his wife, Mrs.​ Veronica Agabi. We are eternally grateful. For more
            information about Derwood Bible Church, check out our website.
          </SlideLeft>
        </p>
      </section>
      <section style={{ background: "#F3F3F3" }} className="pt-10 sm:pt-16">
        <SlideUp>
          <p className="text-center text-8xl font-bold">“</p>
          <p
            style={{ color: "#061354" }}
            className="text-lg text-left sm:text-xl text-center px-5 sm:px-10 lg:px-0 lg:w-2/4 m-auto -mt-10"
          >
            This ministry is borne out of the personal heartache of one of our
            members, who lost her sister to Sickle Cell years ago. The Oyiji
            Odey Clinic offers medical help to those in need, and hope through
            the Gospel of Jesus Christ.
          </p>
        </SlideUp>
        <div className="flex flex-col pt-10 md:pt-0">
          <div className="flex flex-col md:flex-row justify-center items-center md:py-10">
            <div className="sm:w-3/5 md:w-1/3 px-5 sm:px-0">
              <SlideLeft>
                <img src={pics3} alt="" />
              </SlideLeft>
            </div>
            <p className="text-sm font-medium md:w-2/5 py-10 md:py-0 px-5 sm:px-0 md:ml-8 leading-6">
              <SlideRight>
                As part of Derwood Bible Church's commitment to this ministry,
                the church provides medicines for patients. Every month,
                patients receive a monthly supply of vitamins supplements, pain
                medicine and malaria medicine. This provision is made possible
                through donations from our DBC church family who provided the
                medicines that we distributed to our patients during my visit to
                open the new facility in March 2018.
              </SlideRight>
            </p>
          </div>
          <div className="flex flex-col-reverse md:flex-row justify-center items-center md:py-10 leading-6">
            <p className="text-sm font-medium  py-10 md:py-0 px-5 sm:px-0  md:w-2/5">
              <SlideLeft>
                In August of 2019, Dr. Colin Seager, Senior Pastor of Derwood
                Bible Church and Joseph Milliken visited the clinic on a
                missions trip. After 18 years at Ogoja General Hospital, we were
                delighted to move into a facility in Ijibor, Bekwara. We are
                thankful to Hon. Ochiglegor Idagbor, Representative of
                Bekwarra/Obudu/Obanliku Federal Constituency, for his support in
                that effort. We also acknowledge Dr. Asibong, the former Cross
                River State Commissioner for Health and Dr. Betta Edu, the
                present commissioner for health for attending the
                opening ceremony.
              </SlideLeft>
            </p>
            <div className="sm:w-3/5 md:w-1/3 md:ml-8 px-5 sm:px-0">
              <SlideRight>
                <img src={pics4} alt="" />
              </SlideRight>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center md:py-10">
            <div className="sm:w-3/5 md:w-1/3 px-5 sm:px-0">
              <SlideLeft>
                <img src={pics5} alt="" />
              </SlideLeft>
            </div>
            <p className="text-sm font-medium  py-10 md:py-0 px-5 sm:px-0 md:w-2/5 md:ml-8 leading-6">
              <SlideRight>
                The vision of Derwood Bible Church is to reach out to all people
                with Christ's love and the truth of His Gospel, and to build up
                believers in their Christian faith. As part of its Global
                Outreach program, Derwood Bible has been the major support for
                Oyiji Odey Sickle Cell Clinic. Medical supplies and medicines
                are donated and shipped directly to the clinic.Serving God
                Through OOSCP Nurse Mary Adie has been with the clinic since it
                started. Hundreds of families have been touched by her kindness
                and sacrifice. During the outreach program on the second Monday
                of each month, Nurse and our late coordinator, Mr. Jerome Agabi
                would encourage our patients with readings from the scriptures
                before giving them their monthly supply of vitamins and
                malaria medicine.
              </SlideRight>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
}

export default Outreach;
