import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import { ReactComponent as Dropdown } from "../../images/dropdown.svg";
import { ReactComponent as Menu } from "../../images/menu.svg";
const Navbar = ({ dark }) => {
  const width = window.screen.width;
  const [show, setShow] = useState(false);
  useEffect(() => {
    width >= 1024 ? setShow(true) : setShow(false);
  }, [width]);
  return (
    <nav className="bg-nav">
      <div className="flex justify-between items-center py-3 px-5 sm:px-10">
        <div className="sm:w-2/5 flex items-center">
          <Link
            to="/"
            className="btn btn-ghost normal-case text-xl cursor-pointer"
          >
            <img src={logo} alt="Oyiji odey Logo" className="w-16" />
          </Link>
          <div className="font-bold text-white sm:text-black text-xs sm:textl-lg">
            <h2>OYIJI ODEY SICKLE CELL</h2>
            <h2>CLINIC AND RESEARCH CENTRE</h2>
          </div>
        </div>
        <div
          className={show ? "hidden" : "block cursor-pointer"}
          onClick={() => setShow(!show)}
        >
          <Menu stroke={dark ? "#1E1E1E" : "#F3F3F3"} />
        </div>
        <div className={show ? "block relative" : "hidden relative"}>
          <ul className="drop-shadow-lg lg:drop-shadow-none rounded-xl lg:rounded-none h-96 pb-10 lg:pb-0 w-80 lg:w-auto lg:h-auto flex flex-col absolute z-20 -right-3 top-5 bg-white lg:flex-row lg:bg-inherit lg:static justify-between lg:items-center px-1 menu-nav font-bold text-base">
            <li
              className={
                "block text-right sm:text-5xl text-3xl pb-3 lg:hidden cursor-pointer"
              }
              onClick={() => setShow(!show)}
            >
              x
            </li>
            <li
              className={
                "block sm:text-5xl text-lg mb-3 lg:hidden border-b-2 border-black"
              }
              onClick={() => setShow(!show)}
            >
              MENU
            </li>
            <li className="cursor-pointer">
              <Link to="/">Home</Link>
            </li>
            <li className="dropdown">
              <Link className="flex items-center">
                <span>About</span>
                <div className="pl-1">
                  <Dropdown className="-rotate-90 lg:rotate-0" />
                </div>
              </Link>
              <ul className="bg-nav subemenu-center">
                <li className="cursor-pointer">
                  <Link to="/about">Who we are</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/team">Our Team</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/media">Media</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <div className="flex items-center cursor-pointer">
                <span>Services</span>
                <div className="pl-1">
                  <Dropdown className="-rotate-90 lg:rotate-0" />
                </div>
              </div>
              <ul className="bg-nav subemenu-center">
                <li className="cursor-pointer">
                  <Link to="/service">What we do</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/lab">Joyce Lin Lab</Link>
                </li>
                <li className="cursor-pointer">
                  <Link to="/outreach">DBC Outreach</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <Link className="flex items-center">
                <span>Resources</span>
                <div className="pl-1">
                  <Dropdown className="-rotate-90 lg:rotate-0" />
                </div>
              </Link>
              <ul className="bg-nav subemenu-center">
                <li>
                  <Link
                    to="https://www.cdc.gov/ncbddd/sicklecell/facts.html"
                    target="_blank"
                  >
                    CDC
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.hopkinsmedicine.org/health/conditions-and-diseases/sickle-cell-disease#:~:text=Sickle%20cell%20disease%20is%20an,of%20oxygen%20to%20the%20tissues"
                    target="_blank"
                  >
                    John Hopkins
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.nhlbi.nih.gov/health/sickle-cell-disease"
                    target="_blank"
                  >
                    NHLBI
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.hematology.org/education/patients/anemia/sickle-cell-disease"
                    target="_blank"
                  >
                    ASH
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact" className="whitespace-nowrap">
                Contact Us
              </Link>
            </li>
            <li className="pt-5 lg:pt-0">
              <Link
                to="http://gogetfunding.com/oyiji-odey-sickle-cell-clinic-and-research-centre"
                target="_blank"
                className="bg-btn px-8 py-4"
              >
                Donate
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
