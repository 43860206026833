import purpose from "./images/home/purpose.png";
import reason from "./images/home/reason.png";
import faith from "./images/home/faith.png";
import Okoli from "./images/home/okoli.png";
import Dudu from "./images/home/Dudu.png";
import Agi_A from "./images/home/Agi A.png";
import Agi_B from "./images/home/Agi B.png";
import Anna from "./images/home/Anna.png";
import Agi_M from "./images/home/Agi M.png";
import bed from "./images/service/bed.png";
import medications from "./images/service/medication.png";
import community from "./images/service/community.png";
import hand from "./images/service/hand.png";
import grandPhoto1 from "./images/about/photo/photo1.png";
import grandPhoto2 from "./images/about/photo/photo2.png";
import grandPhoto3 from "./images/about/photo/photo3.png";
import grandPhoto4 from "./images/about/photo/photo4.png";
import grandPhoto5 from "./images/about/photo/photo5.png";
import grandPhoto6 from "./images/about/photo/photo6.png";
import grandPhoto7 from "./images/about/photo/photo7.png";
import grandPhoto8 from "./images/about/photo/photo8.png";
import grandPhoto9 from "./images/about/photo/photo9.png";
import grandPhoto10 from "./images/about/photo/photo10.png";
import grandPhoto11 from "./images/about/photo/photo11.png";
import grandPhoto12 from "./images/about/photo/photo12.png";
import grandPhoto13 from "./images/about/photo/photo13.png";
import grandPhoto14 from "./images/about/photo/photo14.png";
import grandPhoto15 from "./images/about/photo/photo15.png";
import team1 from "./images/about/team/team1.png";
import team2 from "./images/about/team/team2.png";
import team3 from "./images/about/team/team3.png";
import team4 from "./images/about/team/team4.png";
import team5 from "./images/about/team/team5.png";
import team6 from "./images/about/team/team6.png";
import team7 from "./images/about/team/team7.png";
import team8 from "./images/about/team/team8.png";
import team9 from "./images/about/team/team9.png";
import team10 from "./images/about/team/team10.png";
import team11 from "./images/about/team/team11.png";
import team12 from "./images/about/team/team12.png";
import team13 from "./images/about/team/team13.png";
import team14 from "./images/about/team/team14.png";
import team15 from "./images/about/team/team15.png";
import team16 from "./images/about/team/team16.png";
import team17 from "./images/about/team/team17.png";
import team18 from "./images/about/team/team18.png";
import team19 from "./images/about/team/team19.png";
export const homeCard = [
  {
    image: purpose,
    title: "PURPOSE",
    text: "Oyiji Odey Sickle Cell Program is a clinic that was established to treat people, mostly children, who suffer from Sickle Cell disease. Sickle Cells Anemia is a genetic disease that affects the red blood cells and disrupts the flow of oxygen to the rest of the body",
  },
  {
    image: reason,
    title: "REASON",
    text: "The death of my sister, Oyiji, at the age of 13 motivated me to reach out to people with this deadly disease. This program has been a tremendous help to sickle cells suffers and their families since September of 2000.",
  },
  {
    image: faith,
    title: "FAITH",
    text: "​Over the years our staff and volunteers have done a tremendous job providing health and counseling services to patients with sickle cell disease, and it involves a great deal of collaboration. My church, Derwood Bible Church has been supporting the clinic through our global outreach ministry",
  },
];

export const patientsCard = [
  {
    image: Agi_B,
    name: "Agi Boniface",
    text: "Agi Boniface is 15yrs old. He recently graduated high school. He was diagnosed with sickle cell disease about 8yrs ago. He's a bit shy but very inquisitive.",
  },
  {
    image: Agi_A,
    name: "Agi Agnes",
    text: "Agi Agnes is 10yrs old and in grade 5 (Primary 5). Diagnosed with sickle cell about 8yrs ago. She's also very friendly, loves to play and has a beautiful smile.",
  },
  {
    image: Anna,
    name: "Ogon Salome",
    text: "Salome is 13yrs old. She is currently in Grade 10 (SS1). She's was diagnosed with sickle cell disease about 7yrs ago and has been our patient since then. She's smart, friendly, bold and well-spoken. She hopes to become a Doctor ",
  },
  {
    image: Agi_M,
    name: "Agi Mark",
    text: "Agi mark is 12yrs old. He's currently in Grade 9 (JSS3). Diagnosed with sickle cell disease about 8yrs ago. He's very friendly and smart.",
  },
  {
    image: Okoli,
    name: "Okoli Michael Olom",
    text: "Okoli Michael Olom, a 12yr old Jss1 boy. Diagnosed with sickle cell disease in 2012 and started clinic attendance at our center in 2012",
  },
  {
    image: Dudu,
    name: "Dudu Confidence",
    text: "Dudu Confidence, a 14yr old ss2 girl. Diagnosed in 2007 of sickle cell and started medications in our facility in 2020.",
  },
];

export const aboutCard = [
  {
    heading: "Mission",
    text: "Our primary mission is to offer hope and the benefit of believing and trusting in God for health and sustenance and to provide immediate health services for people, especially children, who have tested positive for sickle cells disease",
  },
  {
    heading: "Objective",
    text: "Our objective is to provide support and counseling for families and children who have sickle cells. we hope to be able to assist families with low income by providing nutritional support.",
  },
  {
    heading: "Vision",
    text: "​We will encourage our patients and their families to get into a personal relationship with God. We are delighted to partner with people who are committed to making a difference in the lives of other.",
  },
  {
    heading: "Faith",
    text: "Our primary mission is to offer hope and the benefit of believing and trusting in God for health and sustenance and to provide immediate health services for people, especially children, who have tested positive for sickle cells disease",
  },
];

export const serviceCard = [
  {
    icon: medications,
    heading: "Medical",
    text: "Oyiji Odey Sickle Program is primarily designed to provide immediate help for people who suffer from the disease of sickle cell in my hometown in Nigeria.",
  },
  {
    icon: hand,
    heading: "Counselling",
    text: "In the cases where we lose any of our patients, we would like to provide support and counseling for the families involved",
  },
  {
    icon: community,
    heading: "Community",
    text: "One of our major goals is to find ways to connect our patients to some of the resources already available here in the United States. OOSCP clinic is part of Derwood Bible Church Global Outreach program.",
  },
  {
    icon: bed,
    heading: "Research",
    text: "At Oyiji Odey Sickle Cell Clinic, we provide holistic treatment plans for our patients which enables them live a good quality life.We are actively involved in collaborative research studies that involve students and experts in Nigeria and the United States.",
  },
];

export const grandOpeningImgs = [
  grandPhoto1,
  grandPhoto2,
  grandPhoto3,
  grandPhoto4,
  grandPhoto5,
  grandPhoto6,
  grandPhoto7,
  grandPhoto8,
  grandPhoto9,
  grandPhoto10,
  grandPhoto11,
  grandPhoto12,
  grandPhoto13,
  grandPhoto14,
  grandPhoto15,
];

export const adminTeams = [
  {
    pic: team1,
    name: "Katie Odey",
    title: "Founder/Chief Director",
  },
  {
    pic: team2,
    name: "Major General Emanuel Ejim Emeka",
    title: "Chief Consultant",
  },
  {
    pic: team3,
    name: "Joan Will",
    title: "Nurse Consultant",
  },
  {
    pic: team11,
    name: "Mrs. Bridget Okonicha",
    title: "Assistant Coordinator",
  },
  {
    pic: team12,
    name: "Dr Collin Seager",
    title: "Consultant",
  },
  {
    pic: team13,
    name: "Mr James Ngaji",
    title: "Chief Operations Officer",
  },
  {
    pic: team14,
    name: "Mr. Atta Ogbor",
    title: "Board Member",
  },
  {
    pic: team15,
    name: "Mrs. Rose Ngaji",
    title: "Board Member",
  },
  {
    pic: team16,
    name: "Mrs. Kate I Ekpo",
    title: "Coordinator",
  },
  {
    pic: team17,
    name: "Mrs. Veronica Agabi",
    title: "Board Member",
  },
  {
    pic: team18,
    name: "Mr. Fidelis Omang",
    title: "Assistant Coordinator",
    type: "Admin",
  },
  {
    pic: team19,
    name: "Mr. Chikone MacJohnson Odey",
    title: "Board Member",
  },
];
export const staffTeams = [
  {
    pic: team4,
    name: "Damilola Olanrewaju",
    title: "Medical Doctor",
  },
  {
    pic: team5,
    name: " Emmanuel  Onyeledo",
    title: "Medical Laboratory Scientist",
  },
  {
    pic: team8,
    name: "Nelson Agba",
    title: "Nurse",
  },
  {
    pic: team6,
    name: "Mary Adie",
    title: "Nurse",
  },
  {
    pic: team7,
    name: "Grace Oko",
    title: "Cleaner",
  },

  {
    pic: team9,
    name: "Raymond O. Ebodo",
    title: "Security Officer",
  },
  {
    pic: team10,
    name: "Pauline Ekpang",
    title: "Records Officer",
  },
];
