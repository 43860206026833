import React, { useState } from "react";
import forward from "../../images/forward.svg";
import back from "../../images/back.svg";
import { SlideUp } from "../animate";

function CardPagination({ data, itemsPerPage }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [animate, setAnimate] = useState("");
  const totalPages = Math.ceil(data.length / itemsPerPage);

  function handleClick(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = data.slice(start, end);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleCardNav = (next) => {
    if (next === "left") {
      currentPage < 2
        ? setCurrentPage(totalPages)
        : setCurrentPage(currentPage - 1);
      setAnimate("left");
      setTimeout(() => {
        setAnimate("");
      }, 500); // Adjust animation duration as needed
    } else {
      currentPage > totalPages - 1
        ? setCurrentPage(1)
        : setCurrentPage(currentPage + 1);
      setAnimate("right");
      setTimeout(() => {
        setAnimate("");
      }, 500); // Adjust animation duration as needed
    }
  };
  return (
    <>
      {currentData.map((item, i) => (
        <SlideUp
          key={i}
          className={`${
            animate === "left"
              ? "slideLeft"
              : animate === "right"
              ? "slideRight"
              : ""
          } bg-homeCard rounded-xl headerTextColor shadow-lg overflow-hidden w-3/12 mx-7`}
        >
          <img
            className="w-full h-80 object-cover object-center rounded-xl"
            src={item.image}
            alt="patients"
          />
          <h2 className="text-2xl font-bold text-left px-5 pt-7 pb-0 text-black">
            {item.name}
          </h2>
          <p className="text-gray-700 font-semibold text-sm px-5 pb-2 text-left">
            {item.text}
          </p>
        </SlideUp>
      ))}
      <div className="absolute bottom-24">
        <div className="flex justify-between items-center w-screen px-5 sm:px-20 lg:px-32">
          <button
            className="rounded-lg bg-blue-600 w-14 h-14 flex justify-center items-center "
            onClick={() => handleCardNav("left")}
          >
            <img src={back} alt="back-icon" className="w-5" />
          </button>
          <div>
            {pageNumbers.map((number) => (
              <button
                key={number}
                className={
                  currentPage === number
                    ? "active bg-blue-600 rounded-2xl p-2 m-2"
                    : "p-2 m-2 bg-gray-500 rounded-2xl"
                }
                onClick={() => handleClick(number)}
              ></button>
            ))}
          </div>

          <button
            className="rounded-lg bg-blue-600 w-14 h-14 flex justify-center items-center"
            onClick={() => handleCardNav("right")}
          >
            <img src={forward} alt="forward-icon" className="w-5" />
          </button>
        </div>
      </div>
    </>
  );
}

export default CardPagination;
