import bg from "../../images/home/bg.jpg";
import bg1 from "../../images/home/bg1.jpg";
import bg2 from "../../images/home/bg2.jpg";
import bg3 from "../../images/home/bg3.jpg";
import bgm from "../../images/home/bgm.jpg";
import bgm1 from "../../images/home/bgm1.jpg";
import bgm2 from "../../images/home/bgm2.jpg";
import bgm3 from "../../images/home/bgm3.jpg";
import { FadeInWhenVisible } from "../animate";
import ImageSlider from "./Slider";
import { useState, useEffect } from "react";
function IntroSection() {
  const width = window.screen.width;
  const [image, setImage] = useState([]);
  useEffect(() => {
    width >= 600
      ? setImage([bg, bg1, bg2, bg3])
      : setImage([bgm, bgm1, bgm2, bgm3]);
  }, [width]);
  return (
    <div className="intro-container">
      <ImageSlider images={image} />
      <FadeInWhenVisible>
        <div className="flex items-center justify-center text-center">
          <div className="absolute top-1/2 z-2 text-center">
            <p className="text-2xl sm:text-4xl tracking-widest font-bold text-white">
              A Reason to Hope...
            </p>
          </div>
        </div>
      </FadeInWhenVisible>
    </div>
  );
}

export default IntroSection;
