import Navbar from "../components/general/Navbar";
import Banner from "../components/AboutUs/Banner";
import Footer from "../components/general/Footer";
import pic from "../images/contactUs.png";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section>
      <Navbar />
      <Banner
        bg="bg-contact"
        heading="Contact Us"
        text="We would love to hear from you"
      />
      <section style={{ background: "#f3f3f3" }}>
        <p className="text-base font-medium px-5 sm:px-0 sm:w-3/5 mx-auto py-10 sm:py-16 sm:text-center leading-6">
          Besides offering free medical services, we also offer grief counseling
          for people who have lost loved ones through the disease of sickle
          cells. This is a major part of our community outreach program. More
          importantly we want our patients to have a reason to hope. We offer
          comfort and help directly from the word of God
        </p>
        <div className="w-full flex flex-col-reverse lg:flex-row justify-center items-start pb-10 px-5 sm:px-10 sm:px-40">
          <form className="lg:w-4/5 w-full" ref={form} onSubmit={sendEmail}>
            <div className="flex justify-center items-center">
              <input
                type="text"
                placeholder="First Name"
                className="inputBox w-full"
                name="first_name"
              />
              <input
                type="text"
                placeholder="Last Name"
                className="inputBox ml-5 w-full"
                name="last_name"
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                className="inputBox w-full my-9"
                name="user_email"
              />
            </div>
            <div>
              <textarea
                type="text"
                rows={5}
                placeholder="Your message"
                className="inputBox w-full"
                name="message"
              />
            </div>
            <button className="btn-contact">Submit</button>
          </form>
          <img src={pic} alt="" className="lg:ml-20 lg:w-2/5 mb-10 lg:mb-0" />
        </div>
      </section>
      <Footer />
    </section>
  );
}

export default ContactUs;
