import React, { useState, useEffect } from "react";
const ImageSlider = ({ images, slideInterval = 3000 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    const timer1 = setInterval(() => {
      setAnimate(false);
    }, slideInterval - 500);
    const timer = setInterval(() => {
      setAnimate(true);
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, slideInterval);
    return () => {
      clearInterval(timer);
      clearInterval(timer1);
    };
  }, [animate, currentSlide, images.length, slideInterval]);
  return (
    <div className="w-full bg-black">
      <img
        style={{ height: "90vh" }}
        className={`w-full h-screen object-cover ${
          animate ? "slideRight" : ""
        }`}
        src={images[currentSlide]}
        alt="Slider"
      />
    </div>
  );
};

export default ImageSlider;
