import Navbar from "../../components/general/Navbar";
import Footer from "../../components/general/Footer";
import { grandOpeningImgs } from "../../data";

import PlayVideoCard from "../../components/general/PlayVideoCard";
function GrandOpening() {
  return (
    <section
      style={{
        backgroundColor: "#F3F3F3",
      }}
      className="overflow-hidden"
    >
      <Navbar dark />
      <div className="text-center font-bold sm:font-normal mt-40 lg:my-10 mx-5 sm:mx-0">
        <h1 className="text-2xl sm:text-3xl">OYIJI ODEY SICKLE CELL</h1>
        <h1 className="text-2xl sm:text-3xl">
          CLINIC & RESEARCH CENTRE DEDICATION
        </h1>
        <h1 className="font-bold text-xl sm:text-2xl">14th April 2022</h1>
      </div>
      <PlayVideoCard />
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 place-items-center mx-5 sm:mx-16 mb-10">
        {grandOpeningImgs.map((photo) => (
          <img src={photo} alt="" className="w-full" />
        ))}
      </div>
      <Footer />
    </section>
  );
}

export default GrandOpening;
