import { useState } from "react";
import play from "../../images/play.svg";
import video from "../../video/OOSCP Clinic Dedication Promo.mp4";
import home from "../../video/Home.mp4";
function PlayVideoCard({ type }) {
  const [playVideo, setPlayVideo] = useState(false);
  return (
    <>
      {playVideo === false ? (
        <div
          className={`flex justify-center items-center bg-grandOpening ${
            type === "home" ? "w-11/12" : "w-4/5"
          } lg:w-auto mx-auto lg:mx-14 relative my-10`}
        >
          <button
            className="bg-white rounded-full w-1/5 sm:w-auto p-4 shadow-lg flex items-center justify-center"
            onClick={() => setPlayVideo(true)}
          >
            <img src={play} alt="clinic video" className="w-9/12" />
          </button>
        </div>
      ) : (
        <div
          className={`relative ${
            type === "home" ? "w-11/12" : "w-4/5"
          } lg:w-auto mx-auto lg:mx-14`}
        >
          <button
            className="text-3xl sm:text-5xl absolute top-2 sm:top-5 right-10 lg:right-14 text-white z-10"
            onClick={() => setPlayVideo(false)}
          >
            x
          </button>
          <video width="100%" className="mx-auto my-10" controls autoPlay>
            <source src={type === "home" ? home : video} type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
}

export default PlayVideoCard;
